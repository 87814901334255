<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div
        class="modal__entity--cover"
        :style="`--cover: url('${$help.image(selectedEntity.img)}')`"
      >
        <div class="entities-avatar">
          <img
            v-if="selectedEntity.avatar"
            class="modal__entity--brand"
            :src="$help.image(selectedEntity.avatar)"
            :alt="selectedEntity.name"
          />
          <span v-else>
            {{ selectedEntity.name[0] }}
          </span>
        </div>
      </div>

      <div class="modal__content">
        <h3 class="modal__subtitle">Sobre</h3>

        <div class="modal__entity--text">
          {{ selectedEntity.description }}
        </div>

        <h3 class="modal__subtitle">Contato</h3>

        <div class="modal__entity--text">
          <!-- {{ selectedEntity.description }} -->
          <p>{{ selectedEntity.contact }}</p>
          <p>{{ selectedEntity.contact_phone }}</p>
          <p>{{ selectedEntity.url }}</p>
        </div>

        <h3 class="modal__subtitle" v-show="images.length">Fotos</h3>

        <div class="modal__entity--photos">
          <!-- for photos-->
          <silent-box :gallery="images"></silent-box>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import Vue from 'vue'
import VueSilentbox from 'vue-silentbox'

Vue.use(VueSilentbox)

export default {
  props: {
    modal: Boolean,
    selectedEntity: Object
  },

  data: () => ({
    images: []
  }),

  components: {
    baseModal: () => import('@/components/modal')
  },
  watch: {
    async selectedEntity (val) {
      this.images = []
      if (!val) return
      const id = val.user_id
      const url = `entities/getEntity?id=${id}`
      const response = await this.$http.get(url)
      this.images = response.data.data.images
    }
  }
}
</script>
<style scoped>
.entities-avatar {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  font-weight: bold;
  background: #47be71;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  margin-right: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: calc(-75px / 2);
  left: 2rem;
}

.modal__entity--text p {
  line-height: 1.75rem;
}
</style>
